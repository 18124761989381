import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -10px;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    border-color: var(--tagsList);
    background: var(--accent);
    height: 40px;
    margin: 10px;
    color: var(--tagsList);
    padding: 0 10px;
    text-decoration: none;
    font-family: "Trade Winds", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out,
      border-color 250ms ease-in-out;
  }
  a:nth-of-type(2n) {
    border-radius: 20px 43px 16% 44px;
  }
  a:nth-of-type(2n + 1) {
    border-radius: 15% 30%;
  }
  a:nth-of-type(4n) {
    border-radius: 39% 10% 36px 10px;
  }
  a:first-of-type {
    border-radius: 30% 10%;
  }
  a:hover {
    color: var(--tagColor) !important;
    background: radial-gradient(var(--blue), var(--purple)) !important;
    border-color: var(--tagBorder) !important;
    text-shadow: none;
    outline: none;
  }
`

function TagsList({ tags }) {
  return (
    <TagContainer>
      {tags.map((tag, ind) => (
        <Link
          key={`tag-${ind}`}
          to={`/tags/${tag}`}
          aria-label={`Display Posts with tagged as ${tag}`}
        >
          {tag}
        </Link>
      ))}
    </TagContainer>
  )
}

export default TagsList
