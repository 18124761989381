import React from "react"

import { FaChevronRight, FaChevronLeft } from "react-icons/fa"
import { graphql, Link } from "gatsby"

import styled from "@emotion/styled"
import TagsList from "../components/TagsList"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Sharing from "../components/Sharing"
import Bio from "../components/Bio"

const BlogPostContainer = styled.div`
  max-width: 980px;
  margin: 0 auto;
  width: 100%;
`

const BlogPostHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

const BlogPostTitle = styled.h1`
  font-family: 'Trade Winds', cursive;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  width: 100%:
`

const BlogPostDate = styled.h2`
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  align-self: flex-end;
  font-size: 24px;
  width: 100%;
`

const BlogPostContent = styled.div`
  p > a > span {
    position: relative;
  }
  p > a.gastby-resp-image-link:first img {
    z-index: 0;
  }
  h3,
  h2 {
    font-family: "Trade Winds", cursive;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 5px;
  }
  h2 {
    margin-bottom: 30px;
  }
`
const Navigation = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
`
const PrevLink = styled.div`
  text-align: left;
`
const BlogLink = styled.div`
  text-align: center;
`

const NextLink = styled.div`
  text-align: right;
`

export default function Template(props) {
  const { data, pageContext } = props
  const { markdownRemark: post, site } = data
  const siteTitle = site.siteMetadata.title
  const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.resize
    : null
  if (image) {
    image.alt = post.frontmatter.featuredAlt
  }
  const { next, prev } = pageContext

  const BackIcon = FaChevronLeft
  const ForwardIcon = FaChevronRight
  return (
    <Layout {...props} title={siteTitle}>
      <BlogPostContainer>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          image={image}
          pathname={post.frontmatter.path}
          isBlogPost={true}
          datePublished={post.frontmatter.date}
          dateModified={post.frontmatter.lastModified}
          author={post.frontmatter.author}
        />
        <article className="blog-post">
          <BlogPostHeader>
            <BlogPostDate>{post.frontmatter.date}</BlogPostDate>
            <BlogPostTitle>{post.frontmatter.title}</BlogPostTitle>
            <TagsList tags={post.frontmatter.tags || []} />
          </BlogPostHeader>
          <BlogPostContent dangerouslySetInnerHTML={{ __html: post.html }} />
          <Sharing
            pathName={post.frontmatter.path}
            title={post.frontmatter.title}
            social={site.siteMetadata.social}
            siteUrl={site.siteMetadata.siteUrl}
            author={post.frontmatter.author.name}
          />

          <Bio text="Written by Wesley L. Handy who lives and works in Virginia Beach, VA, finding ways to build cool stuff in Gatsby and React." />
          <Navigation>
            <PrevLink>
              {prev && (
                <Link to={prev.frontmatter.path}>
                  <BackIcon /> {prev.frontmatter.title}
                </Link>
              )}
            </PrevLink>
            <BlogLink>
              <Link
                to="/blog/"
                style={{ textAlign: "center", display: "block" }}
              >
                All Posts
              </Link>
            </BlogLink>
            <NextLink>
              {next && (
                <Link className="link next" to={next.frontmatter.path}>
                  {next.frontmatter.title} <ForwardIcon />
                </Link>
              )}
            </NextLink>
          </Navigation>
        </article>
      </BlogPostContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        title
        social {
          twitter
          linkedin
        }
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        image: featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        path
        tags
        title
        featuredAlt
        lastModified(formatString: "MMMM DD, YYYY")
        author {
          name
          email
        }
      }
    }
  }
`
